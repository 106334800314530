import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styles from "./app.module.css"
import Img from "gatsby-image"


const Header = ({ siteTitle }) => (
  <header
    className={styles.header}
  >
    <div
      className={styles.headerInner}
    >
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`
        }}
      >
        DevPal
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
